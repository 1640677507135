export const status=[
  {
    id:"1",
    img:"./img/progress.png",
    status:"In Progress",
    number:"Two Tasks"
  },

  {
    id:"2",
    img:"./img/pending.png",
    status:"Pending",
    number:" Four Tasks"
  },

  {
    id:"3",
    img:"./img/completed.png",
    status:"Completed",
    number:" Three Tasks"
  }

]